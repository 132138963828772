import { IRoseAGGridColumn, dateFormatter } from '@rose/common-ui';
import { IDoctosyncUnmatchedPatients } from '@rose/types';

export function unmatchedPatientsColumnDefs(
  save: (extid: string, patid: string) => Promise<void>,
): IRoseAGGridColumn<IDoctosyncUnmatchedPatients>[] {
  return [
    {
      headerName: 'ID',
      field: 'extid',
      sort: 'asc',
      width: 250,
    },
    {
      headerName: 'Name',
      valueGetter: params =>
        params.data && `${params.data.patientData?.last_name}, ${params.data.patientData?.first_name}`,
    },
    {
      headerName: 'Geburtsdatum',
      field: 'patientData.birthdate',
      valueFormatter: dateFormatter,
      flex: 1,
    },
    {
      headerName: 'PVS-Patid',
      cellRenderer: 'EditPatidCell',
      cellRendererParams: {
        context: {
          save,
        },
      },
      width: 300,
    },
  ];
}
